@import "base";

/* =============================================================================
 *   Element Styling
 * ========================================================================== */
input[type="text"],
.form-text,
textarea,
.form-textarea-wrapper textarea,
.form-item input.error,
.form-item textarea.error,
.form-item select.error /* Override System.css */{
  border:1px solid $form_item_border;
  padding:6px 4px;
  outline:0;
  font-size:1em;
  line-height:normal !important;
  box-sizing:border-box;
  &:hover{
    border-color:$form_item_border_hover;
  }
  &:active,
  &:focus{
    border-color:$form_item_border_active;
  }
}
.form-item input.error,
.form-item textarea.error,
.form-item select.error{
  border-color:$red;
}

input[type="text"],
input.form-text{
  width:auto;
  max-width:100%;
}

.form-submit,
button:not(.ui-multiselect){
  @include btn;
}

select{
  border:1px solid $form_item_border;
  padding:6px 3px;
}

button, input, select, textarea{
  border-radius:$border_radius_small;
}

/* Fieldset */
fieldset {
  border:0 none;
  background:lighten($grey, 15%);
  padding-left:$grid / 2;
  padding-right:$grid / 2;
  .fieldset-wrapper{
    padding-top:1em;
    padding-bottom:0.5em;
  }
  legend{
    border-radius:0;
    background:transparent;
    color:$text_color;
    display:block;
    padding:5px 5px;
    font-size:1.15em;
    margin-left:-5px;
    a,
    a:hover,
    a:active,
    a:focus{
      color:$text_color;
      text-decoration:none;
    }
  }
  fieldset{
    border:1px solid $grey_dark;
    margin-bottom: $grid_vertical;
    &:not(.collapsed){
      box-shadow:0 1px 0 #fff, 0 1px 0 #fff inset;
    }
    &:last-child{
      border:0 none;
    }
    legend{
      font-size: $font_small;
      padding:2px 5px;
      background: $grey;
      border-radius: $border_radius;
    }
  }
}

/* Placeholder */
::-webkit-input-placeholder {
   color: lighten($text_color, 15%);
}
:-moz-placeholder { /* Firefox 18- */
   color: lighten($text_color, 15%);
}
::-moz-placeholder {  /* Firefox 19+ */
   color: lighten($text_color, 15%);
}
:-ms-input-placeholder {
   color: lighten($text_color, 15%);
}

// IE-FIX
.lt-ie9 input[type="text"],
.lt-ie9 input.form-text{
  max-width:none;
}

// Chrome-FIXES
*:focus {
  // Remove this ugly outline thing on focused elements
  outline: 0;
}

/* =============================================================================
 *  Entityforms
 * ========================================================================== */

/* Label Column */
$label_width: 250px;

.entityform{
  h3{
    // Group Titles
    @include grouping-title();
  }
  input, select{
    height:auto;
  }
  fieldset.form-wrapper{
    padding:0;
    legend{
      margin-left:0;
      padding:0;
      font-size:$font_normal;
      color:darken($grey_dark, 50%);
      font-weight:$font_weight_bold;
    }
    .fieldset-wrapper{
      padding-top:8px;
    }
  }
  .form-item:not(.form-type-radio):not(.form-type-checkbox):not(.form-type-date-popup):not(.form-type-date-combo){
    clear:both;
    border-bottom:1px dotted $grey_light;
    padding-bottom:8px;
    margin-bottom:8px;
    padding-left:$grid;
    @media #{$medium_up}{
      padding-left:$label_width;
    }
    @include clearfix-after;
    > label{
      margin-bottom:3px;
      // @include hyphens(auto);
      @media #{$medium_up}{
        float:left;
        margin-bottom:0;
        width:$label_width;
        padding-right:$thin_grid;
        margin-left:-($label_width);
      }
    }
    > input.form-text,
    .form-select{
      width:100%;
      clear:none;
    }
  }
  .fieldset-wrapper{
    // Multivalue table fields
    > table{
      @media #{$medium_up}{
        margin-left:$label_width + ($grid / 2) - 5px;
        width:calc(100% - (#{$label_width} + (#{$grid} / 2) - 5px));
      }
    }
  }
  .form-item{
    &.form-type-textfield,
    &.form-type-select{
      label:not(.multiline-label){
        margin-top: 7px;
      }
    }
  }
  .form-actions{
    clear:both;
    border-top:1px solid $grey;
    background:$grey_light;
    margin-top:$grid_vertical;
    margin-left:-$grid;
    margin-right:-$grid;
    margin-bottom:-$grid_vertical;
    padding-bottom:10px;
    text-align:center;
    .form-submit{
      @include btn-mod('green');
      padding:12px 15px;
    }
  }
  // Fix damn date field
  .container-inline-date,
  .container-inline-date .date-padding,
  .container-inline-date > .form-item,
  .container-inline-date .form-item .form-item,
  .container-inline-date.date-no-float,
  .container-inline-date .form-item input,
  .container-inline-date .form-item select,
  .container-inline-date .form-item option{
    float:none;
    width:auto;
    display:block;
    margin:0;
  }
  // Fix Adress Field
  div.addressfield-container-inline > div.form-item{
    float:none;
    margin-right:0;
  }
}
.submission-text{
  @include box-style('info');
  margin-bottom:$grid_vertical;
}

// Fuer Bewerber - Online Bewerbung overrides
@media #{$medium_up}{
  .entityform.entitytype-stellenbewerbung-form .form-item:not(.form-type-radio):not(.form-type-checkbox):not(.form-type-date-popup):not(.form-type-date-combo):not(.form-type-managed-file) > label{
    width:250px;
    margin-left:-250px;
  }
  .entityform.entitytype-stellenbewerbung-form .form-item:not(.form-type-radio):not(.form-type-checkbox):not(.form-type-date-popup):not(.form-type-date-combo):not(.form-type-managed-file){
    padding-left:250px;
  }
}

// Geschaeftsstelle Field override
.field-name-field-panfr-geschaeftsstelle{
  clear:both;
  padding-top:10px;
  > .form-item{
    @media #{$medium_up}{
      padding-left:50% !important;
    }
    > label{
      @media #{$medium_up}{
        margin-left:-100% !important;
        width:100% !important;
      }
    }
  }
}

// Legals Field
.field-name-field-datenschutz{
  @include box-style();
  @include clearfix-after();
  margin-bottom:$grid_vertical;
  padding-left:40px; // reserved space for the checkobx element
  label.option{
    display:none !important;
  }
  .form-checkbox{
    float:left;
    margin-left: -20px;
    margin-top: 2px;
  }
  .description{
    padding-top:0 !important;
    font-size:$font_normal !important;
  }
}

/* Online Bewerbung */
.group-unterlagen{
  > .form-wrapper:not(:last-child){
    // @include cutline('bottom');
  }
  .field-widget-file-generic{
    legend{
      font-weight:$font_weight_normal !important;
    }
    .fieldset-wrapper{
      padding-top:0 !important;
      .form-type-managed-file{
        > label{
          display:none;
        }
      }
    }
  }
}


/* =============================================================================
 *   Special Form Items
 * ========================================================================== */

/* Progress Bars */
.progress .bar{
  border:1px solid $grey;
  background:white;
  .filled{
    background-color:$highlight;
  }
}

/* Textarea Grippie */
.form-textarea-wrapper textarea{ resize: none; }
.resizable-textarea .grippie {
  background: none repeat scroll 0 0 #EEEEEE;
  opacity:0.5;
  border: 0 none;
  border-radius: 4px 4px 4px 4px;
  float: right;
  height: 30px;
  margin: -35px 30px 0 0;
  position: relative;
  width: 30px;
  z-index: 5;
}
.resizable-textarea .grippie:hover { opacity:1.0; }
.resizable-textarea .grippie:after {
  bottom: 3px;
  content: "y";
  font-family: "iconfont";
  font-size: 16px;
  position: absolute;
  right: 9px;
}

/* Autocomplete Fields */
html.js input.form-autocomplete{
  background-position: 100% 8px;
}
html.js input.throbbing{
  background-position: 100% -12px;
}

/*
 * Password confirmation
 */
.password-parent,
.confirm-parent {
  margin: 0;
}

/* jQuery Widgets */
/* -- Select & Multiselect */
button.ui-multiselect{ width:95% !important; }
/* Fieldgroup: Multipage */
.multipage-controls-list{
  text-align:right;
  margin-top:$grid_vertical;
  margin-bottom:$grid_vertical;
  .multipage-button{
    float:none;
    display:inline-block;
  }
  input.form-submit{
    // Undo some module bullshit
    @include btn();
    font-weight:$font_weight_normal;
    font-family:$font_first;
    font-size:$font_normal;
    text-shadow:none;
  }
}
.multipage-pane:last-child{
  .multipage-controls-list{
    text-align:left;
  }
}

// TODO: Icons wont work here.. its an input button...
.multipage-link-next{
  @include ico('circle-arrow-right');
}
.multipage-link-previous{
  @include ico('circle-arrow-left');
}

/* =============================================================================
 *   Drupal Forms
 * ========================================================================== */

.form-item{
  margin-top:0;
  label{
    font-weight: normal;
    color:$text_color;
    line-height:1.1em;
    &.option{
      color:$text_color;
    }
  }
  &:hover{
    label{
      color:$text_color;
    }
  }
}

/*
 * Highlight marks and form required mark
 */
.marker,
.form-required {
  color: #c00;
}

.form-item .description {
  font-size: 0.85em;
  line-height:normal;
  p{
    margin-bottom:0.25em;
  }
}

.form-checkboxes .form-item,
.form-radios .form-item {}

/* File Fields */
/* -- Auto Upload */
.auto_upload-processed + .form-submit{
  display:none !important;
}

/* Image Fields */
$tabledrag_icon_width: 41px;
$image_preview_width: 120px;
.tabledrag-handle{
  position:relative;
  z-index:3;
}
.image-widget{
  padding-left:$image_preview_width + ($grid / 2) + $tabledrag_icon_width;
  position:relative;
  .filefield-sources-list{
    margin-left:-($image_preview_width + ($grid / 2) + $tabledrag_icon_width);
  }
  .image-preview{
    float:left;
    width:$image_preview_width;
    margin-left:-($image_preview_width + ($grid / 2));
  }
  .form-file{
    margin-left:-($image_preview_width + ($grid / 2) + $tabledrag_icon_width);
  }
}
.tabledrag-toggle-weight-wrapper{
  display:none; // Auf Kundenwunsch ausgeblendet
}
.image-widget{
  .image-preview{ // single value fields
    margin-left:-($image_preview_width + ($grid / 2) + $tabledrag_icon_width);
    + .image-widget-data{
      margin-left:-(($grid / 2) + $tabledrag_icon_width);
    }
  }
}

/* Date Field */
.field-widget-date-popup{

}

/*
 * The submit button
 */
.form-submit {}

.container-inline div,
.container-inline label {
  display: inline;
}

.form-actions{
  padding-top:0.5em;
  @include clearfix-after;
  input{
    margin-right:5px;
  }
}

form.node-form #edit-submit,
.webform-client-form .form-submit{
  @include btn('highlight');
}

form.node-form #edit-delete{
  @include btn('red');
}

@if $dock_form_actions == true{
  body.page-node-edit{ padding-bottom:30px; }
  form.node-form .form-actions{
    position:fixed;
    bottom:0;
    left:0;
    width:100%;
    background:white;
    border-top:1px solid $webks_grey_light;
    text-align:center;
    z-index:99;
    padding:5px 0 10px;
    margin:0;
    .form-submit{
      margin-right:3px !important;
    }
  }
}

/* Drupal Default Forms */
#user-profile-form input[type="text"],
#user-profile-form .form-text,
#user-profile-form textarea,
#user-profile-form .form-textarea-wrapper textarea, /* Override System.css */
#user-profile-form select,
.form-item-mollom-captcha input[type="text"]{ width:auto; }

/* Textareas */
.form-textarea-wrapper{ @include clearfix-after; }

.node-form .form-item,
.node-form .field-type-text-long-form,
.node-form .field-type-text-with-summary{
  @include cutline('bottom');
}
.node-form .field-type-text-long-form .form-item,
.node-form .field-type-text-with-summary .form-item{
  margin-bottom:0;
  padding-bottom:0;
  border:0 none;
  box-shadow:none;
}

/* Labels & Descriptions */
.form-item label { /* The label for a form element */
  display: block;
  font-weight: normal;
}
.form-item label.option { /* The label for a radio button or checkbox */
  display: inline;
  font-weight: normal;
  vertical-align:middle;
}
.inline-fields label{ /* Inline styled Form Fields */ display:inline-block; width:30%;  }

.form-item .description,
.fieldset-description{ /* The descriptive help text (separate from the label) */
  font-size: 0.85em;
  line-height:1.1em;
  opacity: 0.6;
  padding: 0.5em 0;
}
.form-item:hover > .description,
.form-item .description:hover {
  opacity:1.0;
}
.form-type-radio .description,
.form-type-checkbox .description { /* Reset margin for radius & checkboxes */
  margin-left: 0;
}
.form-checkboxes .form-item,
.form-radios .form-item{ /* Pack groups of checkboxes and radio buttons closer together */
  float:none;
  padding: 2px 0;
  margin:0;
  line-height:normal;
}

/* =============================================================================
 *   Form Specific
 * ========================================================================== */

/* View Filter Style */
.filter-guidelines{ margin:10px; }
.filter-guidelines .tips{ padding:10px 0 0 0; }

/* ALL Search Forms */
.search-form{
  margin:0;
  @include clearfix-after;
  .form-type-searchfield{
    position:relative;
    display:block;
    float:left;
    width:64%;
    margin-right:1%;
    @include ico('search', $grey_dark);
    &::before{
      position: absolute;
      top: 4px;
      left: 7px;
    }
  }
  .form-text{
    padding:6px 6px 6px 26px;
    width:100%;
    vertical-align: middle;
  }
  &:hover .form-item::before{
    color:$grey_dark;
  }
  .form-actions{
    display:block;
    float:left;
    padding:0;
    margin:0;
    width:35%;
    .form-submit{
      width:100%;
      margin:0;
      padding:7px 5px; /* Button Reset (Same height as the textfield) */
    }
  }
}

/* Search (Block) */
#edit-advanced{
  .fieldset-legend{
    margin-left:-10px;
    a{
      @include btn('default', 'default', 'process');
    }
  }
}

/* Search (Page) */
.search-results{
  margin:0;
  padding:0;
  .search-result{
    border-bottom:1px solid $grey;
    .title{
      margin-top:0;
      margin-bottom:0.25em;
    }
  }
}

/* Registrierung */
#user-register-form{
  fieldset{
    @include box-style;
  }
  #edit-submit{
    @include btn('green', 'xl');
  }
}

/* Login */
.not-logged-in.page-user .page-title,
.not-logged-in.page-toboggan-denied .page-title {
  @include ico('locked');
}

/* Newsletter Subscriptions */
#edit-subscriptions{
  // TODO ID ersetzen
  @include box-style;
  margin-bottom:1em;
  .form-text{
    width:100%;
  }
  .fieldset-wrapper{
    padding-top:0;
  }
  #edit-newsletters{
    margin-bottom:1em;
  }
}

/* User Profile */
#user-profile-form{
  @include box-style;
}
.pane-user-simplenews{
  h3{
    margin:0;
    font-size:1em;
  }
  dl,
  dd{
    margin:0;
  }
}


/* Mollom */
.form-item-mollom-captcha{
  padding-left:$grid / 2;
  padding-right: $grid / 2;
}

/* reCaptcha */
fieldset.captcha{
  background:transparent;
  text-align:left;
  border-bottom:1px solid $grey;
  margin-bottom:8px;
  .fieldset-wrapper{
    padding-left:$grid;
    padding-right:$grid;
    @include clearfix-after();
    @media #{$medium-up}{
      padding-left:$grid + 304px + $grid;
    }
  }
  > legend{
    display:none;
  }
  .g-recaptcha{
    @media #{$medium-up}{
      float:left;
      margin-left:-(304px + $grid);
      width:304px + $grid;
    }
  }
  .fieldset-description{
    @media #{$medium-up}{
      float:right;
    }
  }
}

/* =============================================================================
 *   Webforms
 * ========================================================================== */
.webform-component{
  margin-bottom:8px;
}
.webform-confirmation{
  @include box-style;
}
.webform-client-form{
  @include box-style;
  .form-submit{
    float:right;
  }
  .form-text,
  .form-select:not(.day):not(.month):not(.year),
  .form-textarea{
    width:100%;
    box-sizing: border-box;
  }
  fieldset{
    border-bottom:1px solid $grey;
    margin-top:10px;
    &:last-of-type{
      border:0 none;
      padding-bottom:0;
    }
  }
}
// Make "Back to form"-Link a button
.page-node-done .links {
  margin-top:1em;
  a{
    @include btn ('default', 'default', 'arrow-left');
  }
}

/* Form Specific */
/* -- Kontakt */
#webform-component-ihre-nachricht legend,
#webform-component-kontakt legend{ position:relative; padding-left:40px; }
#webform-component-ihre-nachricht legend:before,
#webform-component-kontakt legend:before{
  position:absolute;
  left:0;
  top:0;
  content:"K";
  font-family:"iconfont";
  line-height:33px;
  width:33px;
  height:33px;
  background:$highlight;
  color:#fff;
  vertical-align:middle;
  text-align:center;
  border-radius:100%;
}
#webform-component-kontakt legend:before{ content:"b"; }
/* -- -- Anfahrt widget */
#form-destination{
  input[type="text"]{
    width:64%;
    margin-right:1%;
    float:left;
    padding:5px;
  }
  .form-submit{
    width:35%;
    float:left;
    white-space:normal;
    padding:5px;
    height:auto;
  }
}
